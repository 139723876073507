import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Link } from "gatsby";

export default function Sidebar({ story, location }) {
  const { content: { Content: { content }, Headline } } = story || {}
  const heading = (content) => (content?.map(i => i?.text))
  const isActive = (item) => {
        return (location?.hash === '#'+( heading(item.content) ? (heading(item?.content)[0]?.replace(/ /g,"_") ? "active": null) : ""))
  }
  
  return (
    <div>
      <div className="sidebar-navigation">
        <div className="sidebar-navigation-title">
          <Link to={"#" + Headline.replace(/ /g, "_")}> {Headline} </Link>
        </div>

        <div id="sidebar-navigation">
          <div className="article-nav">
            <ul className="article-primary-nav">
              {content
                ?.filter((item) => item?.type === "heading")
                ?.map((item, idx) => {
                  // const data = location?.hash === "#" + (heading(item?.content)[0]?.replace(/ /g, "_"));
                  return (
                    <div key={idx}>
                      <li >
                        {item?.attrs?.level === 2 ? (
                            <Link
                            to={"#" + heading(item?.content)[0]?.replace(/ /g, "_")}
                            className={isActive(item)}
                            partiallyActive={true}
                          >
                            <span>
                              {heading(item?.content)}
                            </span>
                          </Link>
                        ) : (
                          item?.attrs?.level === 3 && (
                            <div className="submenu-nav">
                              <ul>
                                <li className="inner-nav">
                                  <Link
                                  to={"#" + (heading(item.content) ? (heading(item?.content)[0]?.replace(/ /g, "_")) : "")}
                                  className={isActive(item)}
                                  partiallyActive={true}
                                  >
                                    <span>
                                      {heading(item?.content)}
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )
                        )}
                      </li>
                    </div>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
